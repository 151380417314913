import "./Assets/fonts/fonts.css";
import "./Assets/css/animate.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./Assets/css/main.css";
import "./Assets/css/kd-custom.css";
import { Provider } from "react-redux";
import { store } from "./DataStore/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Home from "./Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Snowfall from 'react-snowfall';

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <div className="App">
          <Home />
          {/* <Snowfall style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              // zIndex: -1, // Ensures it doesn't overlap content
            }}/> */}
          <ToastContainer />
          {/* <ReactQueryDevtools /> */}
        </div>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
