import TranslatesContent from "../../../../DataStore/Languages/translatesContent";

const RegisterSuccess = ({openGeneralModal}) => {
    return ( 
        <>
        <div className="register-success">
        <div className="col-12 subTitel text-center" style={{color : "#FCB503", fontSize : "1.35rem"}}>WELCOME TO Win365Casino</div>
        <p className="text-center">
        Win365Casino Customer Verification Team will contact you on your first deposit to comply with 
        <a href="" style={{color:"white", textDecoration:"underline"}} onClick={(e) => {
            e.preventDefault();
            openGeneralModal("customerVerification");}}> “Customer Verification Process” </a>
         as per the regulatory requirement. 
        </p>
        <a href="https://api.whatsapp.com/send?phone=94768562666&text=GIFT">
            <img style={{maxWidth : "220px"}} src="https://storage.googleapis.com/itonecdn/imgWeb/chat-on-whatsup.png" alt="" srcset="" /></a>
        </div>
        
        </>
        
     );
}
 
export default RegisterSuccess;