import guestGames from "../Assets/json/guestgames.json";
import slotGames from "../Assets/json/slot.json";
// import {loadJson} from "../utils";

// import guestGames from "https://storage.googleapis.com/multilang-content/api/siteContent/ballysbreeze-new.com/siteJson/guestgames.json";

// export const BASE_URL = 'https://itonedev.net/api/';
export const BASE_URL = "/api/";
export const LOGIN_URL = `${BASE_URL}loginControl/login`;
export const REGISTER_URL = `${BASE_URL}loginControl/generate-temp-user`;
export const USERDATA_URL = `${BASE_URL}getUserData`;
export const SITE_IMAGES_URL = `${BASE_URL}siteapi/site-images-v2/1`;
export const SITE_IMAGES_URL_TWO = `${BASE_URL}siteapi/site-images-v2/2`;
export const IMAGE_BUCKET_URL = "https://storage.googleapis.com/itonecdn/bellagioimgWeb/new-web/";
export const IMGBUCKETURL = "https://storage.googleapis.com/itonecdn/bellagioimgWeb/new-web/";
export const IMGBUCKETURLNEW = "https://storage.googleapis.com/itonecdn/bellagioimgWeb/new-web";
export const FLAG_IMAGES_URL = "https://storage.googleapis.com/itonecdn/flag/";
export const GET_BACCARAT_SCORE = `${BASE_URL}baccarat/get-score-board-all/`;
// export const SVG_PATH = `${BASE_URL}siteContent/gameresultsvg`;
export const SVG_PATH = `${process.env.PUBLIC_URL}/assets/img`;
export const SVG_BAC_MOBI_PATH = "https://storage.googleapis.com/itonecdn/Baccarat-Mobile-board-new/mobile_bac_board.svg";
export const FUNDTRANSFERURL = `${BASE_URL}cashier/getFundTransfer`;
export const DEOSITBONUSURL = `${BASE_URL}cashier/depositData`;
export const DEPOSITURL = `${BASE_URL}upi/deposit`;
export const CRYPTOURL = `${BASE_URL}crypto/deposit`;
export const LANGUAGE_URL = `${BASE_URL}siteapi/languages`;
export const DEPOSIT_SLIP_URL = `${BASE_URL}cashier/deposit-slp-up-new`;
export const LOGOUTURL = `${BASE_URL}loginControl/logout`;
export const BANK_ACCOUNT_URL = `${BASE_URL}cashier/getBankAcc`;
export const WITHDROWDATA_URL = `${BASE_URL}cashier/withdrawDataProcess`;
export const WITHDROWMETHODS_URL = `${BASE_URL}siteApi/withdrawMethods`;
export const WITHDROW_URL = `${BASE_URL}cashier/withdrawl`;
export const TRANSFER_URL = `${BASE_URL}cashier/showAccountHistory`;
export const CREATE_BANK_ACCOUNT_URL = `${BASE_URL}cashier/register-bank-account`;
export const DELETE_BANK_ACCOUNT_URL = `${BASE_URL}cashier/deleteBankAcc/`;
export const FUND_TRANSFER_TYPE_URL = `${BASE_URL}cashier/transferType`;
export const VERIFY_OTP_URL = `${BASE_URL}loginControl/verify-otp`;
export const REGISTER_USERDATA_URL = `${BASE_URL}loginControl/RegisterUserData`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}loginControl/getPhoneNumberForget`;
export const RECAPTCHA_SITEKEY = "6LeOtB0kAAAAAC0RxBi_ymxlVH2P6e_7XUTrCkyQ";
export const GET_COUNTRIES_URL = `${BASE_URL}loginControl/get-countries`;
export const GET_LANG_CONTENT_URL = `${BASE_URL}siteContent/ballysbreeze.com/`;
export const GET_VERSIONS_URL = `${BASE_URL}siteapi/contentVersion`;
export const SPORT_TRANSACTION_URL = `${BASE_URL}sportBetting/sp_transaction/`;
export const SPORT_LOGIN_URL = `${BASE_URL}sportBetting/sp_getLogin/`;
export const RUMMY_DEBIT_URL = `${BASE_URL}rummy/debit`;
export const RUMMY_CREDIT_URL = `${BASE_URL}rummy/credit`;

export const PROMO_URL = `?tryget=signup#`;
export const BTAG_URL = `?tryget=signup&btag=`;
export const REFERREL_URL = `?tryget=signup&referral=`;
export const PASSWORD_CHANGE = `${BASE_URL}changePassword`;
export const CRYPTO_MERCHANT = `https://merchant.net-cents.com/widget/payment?data=`;
export const POINTS_URL = `${BASE_URL}get_points`;
export const END_ROLLING = `${BASE_URL}rolling-end`;



// export guest games json
export const GUEST_GAMES_DATA = guestGames;
export const SLOT_GAMES_DATA = slotGames;
// export const GUEST_GAMES_DATA =await (await fetch("https://storage.googleapis.com/multilang-content/api/siteContent/ballysbreeze-new.com/siteJson/guestgames.json")).json();;
export const LANG_API = `${BASE_URL}siteContent/`;

// export const

// dummy json files
// export const enJson = `${BASE_URL}siteContent/ballysbreeze.com/en@3.json`;
// export const cnJson = `${BASE_URL}siteContent/ballysbreeze.com/cn@0.9.json`;
// export const hinJson = `${BASE_URL}siteContent/ballysbreeze.com/hin@0.9.json`;
// export const telJson = `${BASE_URL}siteContent/ballysbreeze.com/telugu@0.9.json`;
// export const taJson = `${BASE_URL}siteContent/ballysbreeze.com/tamil@0.9.json`;

export const languageUrl = {
  en: `${BASE_URL}siteContent/ballysbreeze.com/en@3.json`,
  cn: `${BASE_URL}siteContent/ballysbreeze.com/cn@0.9.json`,
  hin: `${BASE_URL}siteContent/ballysbreeze.com/hin@0.9.json`,
  telugu: `${BASE_URL}siteContent/ballysbreeze.com/telugu@0.9.json`,
  tamil: `${BASE_URL}siteContent/ballysbreeze.com/tamil@0.9.json`,
};
