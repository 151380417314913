import { Fragment, useState, useRef } from "react";
import LogoComponent from "./LogoComponent";
import ModalComponent from "../Modal/ModalComponent";
import BeforeLoginContent from "./BeforeLoginContent";
import AfterLoginContent from "./AfterLoginContent";
import { connect } from "react-redux";
import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
import LanguageSwitcher from "./LanguageSwitcher";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import LogoComponentAfter from "./LogoComponentAfter";
import LanguageSwitcherAfter from "./LanguageSwitcherAfter";
import { useNotification, useNotifyView } from "../../services/notification";
import { LOGOUTURL } from "../../DataStore/ApiConstant";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { UserData } from "../../DataStore";
import { fundTransfer } from "../../DataStore";

const HeaderComponent = ({
  login,
  openPopup,
  userData,
  getUserData,
  LanguageContent,
  openGeneralModal,
  fundsTransfer,
  setCashierType,
}) => {
  const userDta = userData.data.data1;
  const userSession = userData.data.session;

  const linkStyle = {
    float: "right",
  };

  const userDropdown = useRef(null);
  const mobileuserDropdown = useRef(null);
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] =
    useState(false);
  const [isNotificationUpdating, setIsNotificationUpdating] = useState(false);
  const history = useHistory();
  const notification = useNotification();

  const openUserDropdown = () => {
    userDropdown.current.classList.toggle("show-userMenu");
    mobileuserDropdown.current.classList.toggle("show-userMenu");
  };
  const logoutUser = (e) => {
    e.preventDefault();
    axios
      .get(LOGOUTURL)
      .then((resp) => {
        // sessionStorage.removeItem("login");
        localStorage.removeItem('login')
        history.push("/");
      })
      .catch((err) => {});
  };
  const notifyView = useNotifyView({
    onSuccess: () => {
      notification.refetch();
    },
  });
  const handleNotificationClick = async (notificationId) => {
    if (isNotificationUpdating) {
      return;
    }
    setIsNotificationUpdating(true);

    try {
      await notifyView.mutateAsync(notificationId);
      notification.refetch();
    } finally {
      setIsNotificationUpdating(false);
    }

    setIsNotificationDropdownOpen(false);
  };
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdownOpen((prev) => !prev);
  };

  return (
    <>
      <div className="d-none d-sm-block fixed-top bg-dark">
        <div className="d-flex flex-wrap justify-content-around  justify-content-lg-start bg-dark align-self-center">
          {/* $$$$$ Logo Area $$$$$ */}
          {/* <div className="item p-lg-1 flex-shrink-2 text-center text-lg-start flex-lg-grow-1"> */}
          <div className="p-2 flex-grow-1">
            <LogoComponent login={login} />
          </div>
          {/* $$$$$ Logo Area END $$$$$ */}
          {/* <div className="item flex-shrink-2 text-center"><img src="images/breez-sport.png" class="img-fluid"></div>
          <div class="item flex-shrink-2 text-center"><img src="images/breez-rummyt.png" class="img-fluid"></div> */}
          {/* $$$$$ Language Dropdown $$$$$ */}
          <div className="pt-lg-1 pt-0 px-2 pb-2">
            <LanguageSwitcher />
          </div>
          {/* $$$$$ Language Dropdown END $$$$$ */}

          {!login ? (
            <BeforeLoginContent openLoginPopup={openPopup} />
          ) : (
            <AfterLoginContent
              userDta={userDta}
              userSession={userSession}
              openPopup={openPopup}
              openGeneralModal={openGeneralModal}
            />
          )}

          {/* <div class="item p-lg-1 align-self-center"><a href="" class="mx-1 icon"><i class="fa fa-power-off "></i></a>
      </div> */}
        </div>
      </div>
      {/* ################# Desktop Nav Bar END ################# */}
      {/* ################# Mobile Nav Bar ################# */}
      {/* <div className="container-fluid d-block d-sm-none fixed-top bg-dark"> */}
      {/* $$$$$ Logo Area $$$$$  */}
      {/* <div className="p-2 flex-grow-1"> */}
      {/* <LogoComponent login={login} /> */}
      {/* <LogoComponentAfter login={login} /> */}
      {/* </div> */}
      {/* $$$$$ Logo Area END $$$$$  */}
      {/* <div className="pt-lg-1 pt-0 px-2 pb-2"> */}
      {/* <LanguageSwitcher /> */}
      {/* </div> */}
      {/* $$$$$ Cashier Button $$$$$  */}

      {!login ? (
        <div className="d-flex d-block d-sm-none fixed-top bg-dark">
          <>
            <div className="p-2 flex-grow-1">
              <LogoComponent login={login} />
            </div>
            <div className="pt-lg-1 pt-0 px-2 pb-2">
              <LanguageSwitcher />
            </div>
            <BeforeLoginContent openLoginPopup={openPopup} />
          </>
        </div>
      ) : (
        <div className="container-fluid d-block d-sm-none fixed-top bg-dark">
          <>
            <>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-3 align-self-center">
                      <LogoComponentAfter login={login} />
                    </div>
                    <div className="col-7 align-self-center text-end">
                      <Link to={"/cashier"}>
                        <button
                          type="button"
                          className="btn btn-warning joinTopbutton text-end"
                        >
                          <TranslatesContent contentKey="cashier" />
                        </button>
                      </Link>
                    </div>
                    <div className="col-2 align-self-center text-end">
                      <LanguageSwitcherAfter />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-6 align-self-center text-start">
                    {/* $$$$$ Wallet with Drop Down  $$$$$ */}
                    <li className="nav-list__item notification">
                      {/* <i className="fa fa-coins" aria-hidden="true" />{" "} */}
                      LKR &nbsp;{" "}
                      {/* {userDta &&
                        userDta.amount &&
                        new Intl.NumberFormat().format(userDta.amount + fundsTransfer?.data?.data?.noncash?.balance)} */}
                          {typeof userDta?.amount !== 'undefined' &&
                          typeof fundsTransfer?.data?.data?.noncash?.balance !== 'undefined' &&
                          new Intl.NumberFormat().format(
                            parseFloat(userDta.amount || 0) + parseFloat(fundsTransfer?.data?.data?.noncash?.balance || 0)
                          )}
                      <ul className="drop-down" style={{ left: 0 }}>
                        <li className="spacer">
                          <span className="arrow" style={{ left: 10 }} />
                        </li>
                        <li className="drop-down__item header">
                          <span className="title">LKR</span>
                          <span className="value">
                            {/* {userDta &&
                              userDta.amount &&
                              new Intl.NumberFormat().format(userDta.amount + fundsTransfer?.data?.data?.noncash?.balance)} */}
                            {typeof userDta?.amount !== 'undefined' &&
                            typeof fundsTransfer?.data?.data?.noncash?.balance !== 'undefined' &&
                            new Intl.NumberFormat().format(
                              parseFloat(userDta.amount || 0) + parseFloat(fundsTransfer?.data?.data?.noncash?.balance || 0)
                            )}
                          </span>
                        </li>
                        <li className="drop-down__item">
                          <span className="title">Wallet</span>
                          <span className="value">
                          {userDta &&
                              userDta.amount &&
                              new Intl.NumberFormat().format(userDta.amount)}
                            {/* {fundsTransfer &&
                              fundsTransfer.data &&
                              fundsTransfer.data.data.wallet &&
                              new Intl.NumberFormat().format(
                                fundsTransfer.data.data.wallet.balance
                              )} */}
                          </span>
                        </li>
                        <li className="drop-down__item">
                          <span className="title">Bonus</span>
                          <span className="value">
                            {" "}
                            {fundsTransfer &&
                              fundsTransfer.data &&
                              fundsTransfer.data.data.bonus_given &&
                              new Intl.NumberFormat().format(
                                fundsTransfer.data.data.bonus_given.balance
                              )}
                          </span>
                        </li>
                        <li className="drop-down__item">
                          <span className="title">Non Cash</span>
                          <span className="value">
                            {fundsTransfer &&
                              fundsTransfer.data &&
                              fundsTransfer.data.data.noncash &&
                              new Intl.NumberFormat().format(
                                fundsTransfer.data.data.noncash.balance
                              )}
                          </span>
                        </li>
                        <li className="drop-down__item">
                          <span className="title">OTP</span>
                          <span className="value">
                            {fundsTransfer &&
                                          fundsTransfer.data &&
                                          fundsTransfer.data.data.otpcash &&
                                          new Intl.NumberFormat().format(
                                            fundsTransfer.data.data.otpcash.balance
                                          )}
                                          </span>
                        </li>
                        {/* <li className="drop-down__item">
                          <span className="title">Total</span>
                          <span className="value">
                            {fundsTransfer &&
                              fundsTransfer.data &&
                              fundsTransfer.data.data.total &&
                              new Intl.NumberFormat().format(
                                fundsTransfer.data.data.total.balance
                              )}
                          </span>
                        </li> */}
                      </ul>
                    </li>
                    {/* $$$$$ Wallet with Drop Down END $$$$$ */}
                  </div>
                  <div className="col-6 align-self-center text-end">
                    {/* $$$$$ Notifications Drop Down $$$$$ */}
                    <a
                      href="#"
                      className="position-relative mx-2 icon nav-list__item notification"
                    >
                      <span
                        className="icon"
                        onClick={toggleNotificationDropdown}
                      >
                        <i className="fa fa-bell" />
                        <span className="total">
                          {" "}
                          {notification.data && notification.data.notifyCount}
                        </span>
                      </span>
                      {isNotificationDropdownOpen && (
                        <ul className="drop-down">
                          <li className="spacer">
                            <span className="arrow"></span>
                          </li>
                          <li className="drop-down__item header">
                            <span
                              className="title"
                              style={{
                                color: "#000",
                                fontWeight: "600",
                              }}
                            >
                              Notifications
                            </span>
                          </li>
                          {notification.isError === false &&
                            notification?.data?.userNotify?.length > 0 && (
                              <>
                                {notification?.data?.userNotify?.map(
                                  (userNotification) => (
                                    <li
                                      className="drop-down__item"
                                      key={userNotification.id}
                                      onClick={() =>
                                        handleNotificationClick(
                                          userNotification.id
                                        )
                                      }
                                    >
                                      {userNotification.notification}
                                    </li>
                                  )
                                )}
                              </>
                            )}
                          {notification.isError === false &&
                            notification?.data?.commonNotify?.length > 0 && (
                              <>
                                {notification?.data?.commonNotify?.map(
                                  (commonNotification) => (
                                    <li
                                      className="drop-down__item"
                                      key={commonNotification.id}
                                      onClick={() =>
                                        handleNotificationClick(
                                          commonNotification.id
                                        )
                                      }
                                    >
                                      {commonNotification.notification}
                                    </li>
                                  )
                                )}
                              </>
                            )}

                          {notification.isError === false &&
                            (!notification?.data?.userNotify ||
                              notification?.data?.userNotify?.length === 0) &&
                            (!notification?.data?.commonNotify ||
                              notification?.data?.commonNotify?.length ===
                                0) && (
                              <li className="drop-down__item">
                                No notifications available
                              </li>
                            )}
                        </ul>
                      )}
                    </a>
                    <span className="icon">
                      <a
                        href=""
                        className="mx-2 icon"
                        id="defaultDropdown2"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        <i className="fa fa-user"></i>
                      </a>
                      <a
                        href=""
                        onClick={(e) => logoutUser(e)}
                        className="heder-rounded-icon"
                      >
                        <i className="fa fa-power-off"></i>
                      </a>
                    </span>
                    {/* $$$$$ Notifications Drop Down END $$$$$ */}
                  </div>
                </div>
              </div>
              {/* <div className="row">
                  <div className="col-12 align-self-center"> */}
              {/* <LanguageSwitcher /> */}
              {/* <AfterLoginContent login={login}
                      userDta={userDta}
                      userSession={userSession}
                      openPopup={openPopup}
                      openGeneralModal={openGeneralModal} /> */}
              {/* </div>
                      </div> */}
            </>
          </>
        </div>
      )}
      {/* $$$$$ Language Dropdown END $$$$$ */}
    </>
  );
};

const setStatetoProps = (state) => {
  return {
    userData: state.userData,
    LanguageContent: state.SiteLanguages,
    fundsTransfer: state.FundTransfers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFUndTransfers: () => dispatch(fundTransfer()),
    getUserData: () => dispatch(UserData()),
  };
};

export default connect(setStatetoProps, mapDispatchToProps)(HeaderComponent);
