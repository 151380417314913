import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HeaderComponent from "../Header/HeaderComponent";
import { connect } from "react-redux";
import { UserData } from "../../DataStore";
import MainBanner from "../Banners/MainBanner";
import FeaturedGames from "../General/FeaturedGames";
import InnerBanner from "../General/InnerBanner";
import FooterComponent from "../Footer/FooterComponent";
import PoyaBanneer from "../Poya/PoyaBanneer";
import LowBalanceBanner from "../Modal/LowBalance/LowBalanceBanner";
import CashierModalWrapper from "../Modal/CashierModal/CashierModalWrapper";
import { useState } from "react";
import GamesWrapper from "../General/GameboardWrapper";
import Promotions from "./Promotions";
import SportsBetArea from "./SportsBetArea";
import UserInfo from "../Header/UserInfo";
import { useAtom } from "jotai";
import ChangePasswordComponent from "../Modal/GeneralModal/ChangePasswordComponent";
import { preLoader } from "../../DataStore/atom";

const Lobby = ({
  opencashier,
  getUserData,
  userData,
  selectedGameAreaType,
  setSelectedGameType,
  modalType,
  modalStatus,
  openPopup,
  closePopup,
  generalModalStatus,
  generalModalType,
  openGeneralModal,
  gameWrapperArea,
  setCashierType,
}) => {
  const [preLoaderCoin, setPreLoader] = useAtom(preLoader);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userDta = userData.data.data1;
  const userSession = userData.data.session;
  const history = useHistory();
  const checkLogin = () => {
    !localStorage.getItem('login')&&history.push('/')
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if(userDta?.did_change_password == false){
      openModal()
    }
  }, [userDta]);

  useEffect(() => {
    checkLogin();
    getUserData();
    closePopup();
  }, []);
  useEffect(() => {
    setPreLoader(true);
    const timer = setTimeout(() => {
      setPreLoader(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const [cashierPopupStatus, setCashierPopupStat] = useState("inactive");
  let demoCashierType = ""
  demoCashierType = !userData.isLoading && userData?.data?.session?.typest !== "demo" ?  "deposit" : "transfer"
  const goToCashier = (type = demoCashierType) => {
    setCashierType(type);
    history.push("/cashier");
  };
  const cashierPopupActions = (type) => {
    switch (type) {
      case "active":
        setCashierPopupStat("active");
        break;
      case "inactive":
        setCashierPopupStat("inactive");
        break;
      default:
        setCashierPopupStat("inactive");
    }
  };
  const chrck = () => {
   alert()
  };
  return (
    <div style={{position: "relative"}}>
      {/* header area */}
      <HeaderComponent
        userData={userData}
        login={true}
        openPopup={openPopup}
        openGeneralModal={openGeneralModal}
        setCashierType={setCashierType}
        setSelectedGameType={setSelectedGameType}
      />
      {/* banner area */}
      <MainBanner login={true} />
      {/* featured games */}
      <FeaturedGames
        setSelectedGameType={setSelectedGameType}
        openGeneralModal={openGeneralModal}
      />
      {/* inner banner */}
      <InnerBanner login={true} />
      {/* <Promotions
        selectedGameAreaType={selectedGameAreaType}
        login={true}
        gameWrapperArea={gameWrapperArea}
      /> */}
      {/* game tabs */}
      <GamesWrapper
        selectedGameAreaType={selectedGameAreaType}
        login={true}
        gameWrapperArea={gameWrapperArea}
      />
      {/* <SportsBetArea
        selectedGameAreaType={selectedGameAreaType}
        login={true}
        gameWrapperArea={gameWrapperArea}
      /> */}
      {/* footer area */}
      <FooterComponent
        login={true}
        openPopup={openPopup}
        generalModalStatus={generalModalStatus}
        closePopup={closePopup}
        generalModalType={generalModalType}
        openGeneralModal={openGeneralModal}
        goToCashier={goToCashier}
      />
      {/* cashier modal */}
      {/* <CashierModalWrapper
        userData={userData}
        modalType={modalType}
        closePopup={closePopup}
        status={modalStatus}
      /> */}
      <UserInfo
        openPopup={openPopup}
        userSession={userSession}
        userDta={userDta}
        openGeneralModal={openGeneralModal}
        goToCashier={goToCashier}
        setSelectedGameType={setSelectedGameType}
      />
      <ChangePasswordComponent isOpen={isModalOpen} onClose={closeModal} close={true}/>
      <PoyaBanneer />
      <LowBalanceBanner userData={userData}/>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: () => dispatch(UserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lobby);
